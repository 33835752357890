import React, { Component } from "react"
import { Row, Col } from "react-flexbox-grid"

import Hero from "../../../components/Hero"
import Layout from "../../../components/Layout"
import ContentBlock from "../../../components/ContentBlock"
import HelmetWrapper from "../../../components/HelmetWrapper"
import Accordion from "../../../components/Accordion"

import aboutUsImg from "../../../assets/images/progressAtTheHeartIcon.png"
import headerHero from "../../../assets/images/progressAtTheHeartHero.png"
import CalloutBlock from "../../../components/CalloutBlock"
import arrow from "../../../assets/images/chevron-circle-right-solid.svg"

import "./index.scss"
import "../../../scss/_carouseloverride.scss"

const pageTitle = 'Progress at the Heart | Harmony Biosciences'
const pageDescription =
  "Our annual Progress at the Heart program supports innovative programs that address disparities and inequities in the rare neurological disease community."
const pageKeywords = ""

const currentVideo = {
  wistiaID: "1in2y73bsg",
}

class ourCulture extends Component {
  constructor() {
    super()
  }

  dreamBigBtn = () => {
    const isGA = typeof window.ga !== "undefined"

    if (isGA) {
      window.ga(
        "send",
        "event",
        "External Link",
        "click",
        "#NarcolepsyDreamBigWalk"
      )
    }
    window.open(
      "https://www.linkedin.com/feed/topic/?keywords=%23NarcolepsyDreamBigWalk",
      "_blank"
    )
  }

  learnMoreBtn = () => {
    const isGA = typeof window.ga !== "undefined"

    if (isGA) {
      window.ga(
        "send",
        "event",
        "External Link",
        "click",
        "Learn More - Culture"
      )
    }
    window.open("https://lnkd.in/d59_6eX", "_blank")
  }

  render() {
    return (
      <Layout jobCode="US-HAR-2200069/Oct 2022">
        <div id="our-culture">
          <HelmetWrapper
            pageTitle={pageTitle}
            pageDescription={pageDescription}
            pageKeywords={pageKeywords}
            slug="/about-us/our-culture"
          />
          <Hero bg={headerHero}>
            <h1>
              <strong>Progress at the Heart</strong>
            </h1>
          </Hero>
          <ContentBlock id="patients-at-the-heart" color="rose">
            <Row>
              <Col xs={12} md={2}>
                <Row center="xs">
                  <Col>
                    <img src={aboutUsImg} className="" />
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={10}>
                <h2>
                  <strong>Progress at the Heart Program</strong>
                </h2>
                <p>Individuals affected by rare neurological diseases deserve equal access to treatment and care. This requires coming together as one community to address the root causes of health disparities, including race, ethnicity, gender, sexual orientation, education, income, and geographic location, to ensure that all patients with rare neurological diseases receive appropriate care.</p>
                <p>
                  Harmony Biosciences’ <strong>Progress at the Heart</strong> program supports novel initiatives addressing disparities and inequities in access to treatment and care in the rare neurological disease community.
                </p>

                <p>
                  <strong>
                    Harmony Biosciences is pleased to announce the following open application period for this year’s Progress at the Heart program.
                  </strong>
                </p>
                <ul class="list-with-extra-spacing">
                  <li>
                    <strong>February 26 &#8211; May 24:</strong> Notification
                    sent to awardees by July 2024
                  </li>
                </ul>

                <h2 className="gray" style={{color:"#636569"}}>TO APPLY FOR FUNDING, CLICK <a className="green" target="_blank" href="https://harmony.medcompli.com/">HERE</a>.</h2>
                <p>
                <ol className="patients-ol relative ml-25">
                  <li className="first">Click "Progress at the Heart"</li>
                  <li className="second">Application materials, including submission and budget form, are available via this portal</li>
                  <li className="third">If applying from a 501(c) organization, kindly include your 501(c)(3) tax-deductible letter and your organization’s most recent Form 990</li>
                </ol>
                </p>

                <p>
                  Should you have any questions about portal navigation or specific questions about applications, please refer to the following email address:
                </p>
                <p>
                  <ul className="patients-ul list-with-extra-spacing">
                    <li>
                      <a
                        href="mailto:progressattheheart@harmonybiosciences.com"
                        target="_blank"
                      >progressattheheart@harmonybiosciences.com
                      </a>
                    </li>
                  </ul>
                </p>

                <Accordion
                  title={"View More Program Details"}
                  injectHTML={false}
                >
                  <div>
                    <strong>
                      During the open application period in 2024, Harmony
                      Biosciences will accept applications that focus on one or
                      more of the following categories:
                    </strong>
                  </div>
                  <ul class="list-with-spacing">
                    <li>
                      <strong>Community Programs:</strong> People-centered, community-led programs to improve awareness and treatment in the rare neurological disease communities and help achieve greater equality for rare neurological disease patients experiencing disparities when it comes to receiving appropriate treatment and care
                    </li>
                    <li>
                      <strong>Education:</strong> General public and healthcare provider education and programs that increase awareness and decrease time to diagnosis of rare neurological diseases with an emphasis on addressing health disparities such as race or ethnicity, gender, education, income, or geographic location
                    </li>
                    <li>
                      <strong>Public Health:</strong> Creation of or modifications to public policies that address health disparities
                    </li>
                    <li>
                      <strong>
                        Other proposals that help achieve greater equality within rare neurological disease communities
                      </strong>
                    </li>
                  </ul>
                  <p>
                    <strong>Funding Application Information</strong>
                  </p>
                  <div>
                    <strong>
                      Application submission should include detailed information
                      on:
                    </strong>
                  </div>
                  <ul class="list-with-spacing">
                    <li>Description and type of organization</li>
                    <li>Program title and summary</li>
                    <li>Key deliverables and program milestones</li>
                    <li>Evaluation plan</li>
                    <li>Communication and results plan</li>
                    <li>Financial information/detailed budget for project</li>
                  </ul>
                  <p>
                    <strong>
                      Harmony Biosciences will request a program update one year
                      after award.
                    </strong>
                  </p>
                  <div>
                    <strong>Additional details:</strong>
                  </div>

                  <ul class="list-with-spacing">
                    <li>
                      Applicants must be a{" "}
                      <strong>
                        501(c)(3) or similarly credentialed nonprofit
                        organization
                      </strong>
                    </li>
                    <li>
                      No minimum funding amount; maximum funding request per
                      application: $50,000
                    </li>
                  </ul>
                  <p>
                    <strong>
                      Incomplete proposals will not be considered for review.
                    </strong>
                  </p>
                  <p>
                    Harmony Biosciences cannot fund projects that will provide a benefit to Harmony Biosciences other than the “incidental and tenuous” benefit that Harmony Biosciences receives in the form of goodwill, name recognition, and publicity.
                  </p>
                  <div>

                    <p>
                      <strong>
                        We will not consider proposals that include any of the
                        following:
                      </strong>
                    </p>
                  </div>
                  <ul class="list-with-spacing">
                    <li>
                      Programs that reference Harmony Biosciences products or
                      product candidates, or seek to measure the impacts of
                      Harmony Biosciences products
                    </li>
                    <li>
                      Organizations that discriminate on the basis of age, race,
                      sex, religion, sexual orientation, or disability
                    </li>
                    <li>
                      Funding for events or programs that have aIready occurred
                    </li>
                    <li>
                      Political organizations, campaigns, or direct lobbying
                      activities
                    </li>
                    <li>
                      Religious organizations or groups whose activities are
                      primarily sectarian in purpose
                    </li>
                    <li>
                      Programs or proposals that supplement an organization's
                      income or offset its expense in a manner that benefits,
                      directly or indirectly, a healthcare provider's
                      compensation
                    </li>
                  </ul>
                 <p class="footnote-small">
                    Harmony reserves the right to withdraw this program prior to selecting and making award decisions.
                    <br />
                    Harmony reserves the right to use awardee organizations and winning programs in media materials.
                    <br />
                    Harmony reserves the right to partially fund program
                    requests.
                    <br />
                    Applications and support materials must be submitted in
                    English.
                  </p>
                </Accordion>

                <Accordion
                  title={"Frequently Asked Questions"}
                  injectHTML={false}
                >
                  <div>
                    <strong>Can I submit for more than one application?</strong>
                  </div>
                  <p>
                    Yes. Each program application must be submitted separately.
                  </p>
                  <div>
                    <strong>
                      Can my organization apply for funding in collaboration
                      with another organization?
                    </strong>
                  </div>
                  <p>
                    Yes. Collaborative proposals between individuals and/or
                    advocacy/community groups and/or academic institutions
                    and/or medical societies are allowed.
                  </p>
                  <div>
                    <strong>
                      Are local and regional programs being considered?
                    </strong>
                  </div>
                  <p>
                    Yes. Local, regional, and national programs will be
                    considered. At this time, the{" "}
                    <strong>Progress at the Heart</strong> funding will be
                    awarded to programs in the U.S. only.
                  </p>
                  <div>
                    <strong>
                      Is there any guarantee of continuation of funding after
                      awarded?
                    </strong>
                  </div>
                  <p>
                    No. There is no guarantee an awarded program will receive
                    funding in the future, even upon the continuation of a
                    proposed program and beyond the proposal description.
                  </p>
                  <div>
                    <strong>
                      May I submit a request for an activity that has already
                      occurred?
                    </strong>
                  </div>
                  <p>
                    No, <strong>Progress at the Heart</strong> does not fund
                    activities that have already occurred but will consider
                    support for ongoing and existing programs.
                  </p>
                  <div>
                    <strong>
                      What is the maximum budget that can be allocated for incidental costs?
                    </strong>
                  </div>
                  <p>
                    Administrative or Indirect Costs must be limited to 20% of Harmony Funding Request.
                  </p>
                  <div>
                    <strong>
                     Can budget provided by Harmony be used for travel costs?
                    </strong>
                  </div>
                  <p>
                    Funding support cannot be used for costs of travel, lodging, or personal expenses.
                  </p>
                  <div>
                    <strong>
                      I have a detailed question about my proposal. Who do I
                      contact with a question?
                    </strong>
                  </div>
                  <p>
                    Please address all questions regarding your application to{" "}
                    <a href="mailto:progressattheheart@harmonybiosciences.com">
                      progressattheheart@harmonybiosciences.com
                    </a>
                    .
                  </p>
                </Accordion>
              </Col>
            </Row>
          </ContentBlock>
          <ContentBlock id="learn" color="gray">
            <Row>
              <Col xs={12} md={12}>
                <div className="pl-20">
                  <h2>2023 Program Awards</h2>
                  <div>
                    <p>
                      Now in its third year,{" "}
                      <strong>Progress at the Heart</strong> has supported 15 initiatives, including the following programs:
                    </p>

                    <p>
                      <a href="https://www.aan.com/" target="_blank">
                        American Academy of Neurology (AAN)
                      </a>
                      <br />
                      <strong>Health Care Equity Program</strong>
                      <br />
                      The annual Health Care Equity Program enhances 10
                      neurologists’ knowledge of disparities and promotes more
                      impactful neurology and healthcare policies. Programs
                      include meetings, educational workshop sessions, and
                      individual projects to help close health disparity gaps.
                    </p>

                    <p>
                      <a
                        href="https://www.geisinger.org/about-geisinger/geisinger-foundation"
                        target="_blank"
                      >
                        Geisinger Health Foundation
                      </a>
                      <br />
                      <strong>Wake Up and Learn</strong>
                      <br />
                      Wake Up and Learn virtual K-12 educational and
                      surveillance program facilitates a culture shift towards a
                      valuation of sleep as essential for health and removing
                      barriers to timely recognition, diagnosis, and management
                      of sleep disorders in school-aged children. The 2024
                      program will expand the video curriculum, translate
                      materials into Spanish, and assess impact through
                      interviews with a focus on diverse geographic, ethnic, and
                      gender perspectives.
                    </p>
                    <p>
                      <span
                        style={{
                          fontSize: "16px",
                          fontWeight: "700",
                          color: "#61a60e",
                        }}
                      >
                        Rare And Black
                      </span>
                      <br />
                      <strong>The Restful Revolution</strong>
                      <br />
                      The Restful Revolution, an innovative pilot program, will
                      provide support to up to five Historically Black Colleges
                      and Universities in the Washington, DC, and Baltimore area
                      for the 2023-2024 academic school year. Its mission is to
                      educate and empower students to cultivate healthy sleep
                      habits and raise awareness about sleep disorders.
                    </p>
                    <p>
                      <a href="https://www.arizona.edu/" target="_blank">
                        The University of Arizona
                      </a>
                      <br />
                      <ul style={{ marginLeft: "15px" }}>
                        <li
                          style={{
                            paddingLeft: "10px",
                            paddingBottom: "10px",
                            listStyleType: "disc",
                          }}
                        >
                          <strong>
                            Platicamos Sueno: Engaging Community Health Workers
                            in Sleep Health at the US-Mexico Border
                          </strong>
                          <br />
                          This program expands upon existing initiatives at the
                          US-Mexico border through collaboration with the
                          Mariposa Community Health Center and their “Platicamos
                          Salud” program to train community health workers
                          (“promotoras de salud”) to improve access to quality
                          care through panels, in-language educational
                          resources, and training sessions.{" "}
                        </li>
                        <li
                          style={{ paddingLeft: "10px", listStyleType: "disc" }}
                        >
                          <strong>
                            QuE PASE? (“What Happens?”): Querying Everyday
                            Perceptions About Sleep Experiences
                          </strong>
                          <br />
                          This project empowers Hispanic and Latino families at
                          the US-Mexico border to address sleep disorder and
                          awareness gaps through a family-based approach. The 20
                          families already enrolled will have access to new
                          programming focused on recognizing symptoms and two
                          sessions with a psychologist focused on sleep medicine
                          to promote overall health well-being.
                        </li>
                      </ul>
                    </p>
                    <p>
                      <a href="https://www.utah.edu/" target="_blank">
                        The University of Utah
                      </a>
                      <br />
                      <strong>
                        Nuestro Sueno: Focus Groups for the Development of a
                        Culturally-Tailored Sleep Health and CPAP Adherence
                        Intervention for Latino Couples
                      </strong>
                      <br />
                      This project aims to fill the gap of culturally adapted
                      positive airway pressure (PAP) adherence interventions for
                      Latinos through rigorous qualitative research and feedback
                      from patients and community stakeholders. The insights
                      will inform the intervention’s cultural appropriateness
                      and acceptability in the pursuit of future research.
                    </p>
                    <p>
                      Click to learn more about what programs{" "}
                      <strong>Progress at the Heart</strong> supported in{" "}
                      <a
                        rel="noopener noreferrer"
                        target="_blank"
                        href="https://www.harmonybiosciences.com/newsroom/harmony-biosciences-announces-winners-of-patients-at-the-heart-and-progress"
                      >
                        2021
                      </a>
                      ,&nbsp;
                      <a
                        rel="noopener noreferrer"
                        target="_blank"
                        href="https://www.harmonybiosciences.com/newsroom/harmony-biosciences-announces-2022-funding-recipients-of-patients-at-the"
                      >
                        2022
                      </a>
                      , and{" "}
                      <a
                        rel="noopener noreferrer"
                        target="_blank"
                        href="https://www.harmonybiosciences.com/newsroom/harmony-biosciences-showcases-the-newest-patients-at-the-heart-and-progress"
                      >
                        2023
                      </a>
                      .
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </ContentBlock>

          <ContentBlock id="quote1" color="orange" className="">
            <Row>
              <Col xs={12} md={12}>
                <div>
                  <p class="quote-content quote-undefined">
                    “Racial disparities in healthcare are a medical emergency.
                    To ensure equitable care, at Duke Neurology, we are building
                    a system to identify and eliminate any variations in
                    treatments provided to patients of different races and
                    ethnicities. With funding through the Progress at the Heart &nbsp;program, we will be able to build the{" "}
                    <strong style={{ fontWeight: "700" }}>
                      Duke Health Disparities in Neurology Scorecard
                    </strong>{" "}
                    that will provide easy-to-understand scoring to indicate
                    where we are achieving our mission and where we can improve.
                    Ultimately, the scorecard template will be broadly available
                    with the goal of reducing health disparities nationwide.”
                  </p>
                  <p class="bold">
                    — Andrew Spector, MD, Neurologist, Sleep Medicine Specialist{" "}
                  </p>
                </div>
              </Col>
            </Row>
          </ContentBlock>

          <ContentBlock id="footnotes" color="white">
            <Row>
              <Col xs={12} md={12}>
                <p class="footnote-small">
                  All awards are paid directly to the requesting organization,
                  and never to an individual. No funding is offered or provided
                  in exchange for endorsing or prescribing Harmony products or
                  for a commitment to continue prescribing Harmony products. In
                  other words, any quid pro quo is strictly prohibited.
                </p>
                <p class="footnote-small">
                  Any funding provided by Harmony will be in compliance with all
                  applicable laws, rules, and regulations.
                </p>

              </Col>
            </Row>
          </ContentBlock>

          <ContentBlock color="white" className="last">
            <Row className={"callout-row"}>
              <Col xs={12} md={6}>
                <CalloutBlock
                  className="text-only bg-blue"
                  link="/funding-programs/patients-at-the-heart/"
                >
                  <h2>
                    <strong>PATIENTS AT THE HEART</strong>
                  </h2>
                  <img className="callout-arrow" src={arrow} />
                </CalloutBlock>
              </Col>
              <Col xs={12} md={6}>
                <CalloutBlock
                  className="text-only bg-orange"
                  link="/funding-programs/external-funding"
                >
                  <h2>
                    <strong>EXTERNAL FUNDING</strong>
                  </h2>
                  <img className="callout-arrow" src={arrow} />
                </CalloutBlock>
              </Col>
            </Row>
            <Row className="callout-bottom">
              <Col xs={12} md={6}>
                <CalloutBlock
                  className="text-only bg-green"
                  link="/funding-programs/medical-education-grants"
                >
                  <h2>
                    <strong>MEDICAL EDUCATION GRANTS</strong>
                  </h2>
                  <img className="callout-arrow" src={arrow} />
                </CalloutBlock>
              </Col>
              <Col xs={12} md={6}>
                <CalloutBlock
                  className="text-only bg-gray"
                  link="/funding-programs/investigator-sponsored-studies"
                >
                  <h2>
                    <strong>INVESTIGATOR SPONSORED STUDIES</strong>
                  </h2>
                  <img className="callout-arrow" src={arrow} />
                </CalloutBlock>
              </Col>
            </Row>
          </ContentBlock>
        </div>
      </Layout>
    )
  }
}

export default ourCulture
